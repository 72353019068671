/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"muzeum-meteoritu.cz - Kontakty"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1m8w4r4 js-anim  --anim3 --anim-s3 --center" anim={"3"} name={"uvod"} animS={"3"} style={{"paddingTop":187,"marginBottom":19,"paddingBottom":354}} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37955/27a54b8d93ee47d29c6fe7f7ca85578e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/27a54b8d93ee47d29c6fe7f7ca85578e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37955/27a54b8d93ee47d29c6fe7f7ca85578e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/27a54b8d93ee47d29c6fe7f7ca85578e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37955/27a54b8d93ee47d29c6fe7f7ca85578e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37955/27a54b8d93ee47d29c6fe7f7ca85578e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/27a54b8d93ee47d29c6fe7f7ca85578e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/27a54b8d93ee47d29c6fe7f7ca85578e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box title-box--center fs--62 w--900" style={{"maxWidth":700}} content={"Kontaktujte nás"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="js-anim  --anim3 --anim-s3 pb--60" anim={"3"} name={"zhxkxhj3k1p"} animS={"3"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Muzeum Meteoritů s.r.o.<br>"}>
              </Title>

              <Text className="text-box" content={"IČO: 21345058\n<br>Adresa: Dobrá 957, 739 51 Dobrá\n<br>info@muzeum-meteoritu.cz<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3 --center" anim={"3"} name={"7z2pjteaqay"} animS={"3"} style={{"paddingTop":2,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"Muzeum Meteoritů s.r.o.&nbsp; &nbsp;© 2024  Vytvořeno s ❤️ k vesmíru"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}